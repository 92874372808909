<template>
	<div class="indexBox">
		<div class="loginFromBox">
			<p style="font-size: 14px; line-height: 1.5"><a>找回密码</a></p>
			<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmitRetrieve">
				<a-form-item v-bind="formItemLayout">
					<a-input
						style="width: 368px; height: 40px"
						placeholder="输入重置密码的邮箱"
						v-decorator="[
							'email',
							{
								rules: [
									{
										type: 'email',
										message: '请输入正确的邮箱'
									}
								]
							}
						]"
					/>
				</a-form-item>

				<a-form-item class="centerBox">
					<a-button type="primary" html-type="submit" class="login-form-button">确定重置</a-button>
				</a-form-item>
			</a-form>
		</div>
	</div>
</template>

<script>
import { message } from 'ant-design-vue'
import api from '../../api/api'
export default {
	beforeCreate() {
		this.form = this.$form.createForm(this, { name: 'normal_login' })
		this.form = this.$form.createForm(this, { name: 'register' })
	},
	data() {
		return {
			des: '',
			comeman: 60,
			getcodes: false,
			confirmDirty: false,
			autoCompleteResult: [],
			formItemLayout: {
				labelCol: {
					xs: { span: 24 },
					sm: { span: 8 }
				},
				wrapperCol: {
					xs: { span: 24 },
					sm: { span: 16 }
				}
			},
			tailFormItemLayout: {
				wrapperCol: {
					xs: {
						span: 24,
						offset: 0
					},
					sm: {
						span: 16,
						offset: 8
					}
				}
			}
		}
	},
	methods: {
		//找回密码申请
		handleSubmitRetrieve(e) {
			e.preventDefault()
			this.form.validateFieldsAndScroll(async (err, values) => {
				const data = await api.resetSend(values)

				if (data.status==0) {
					message.success('邮件发送成功')
				}else{
					message.error('找回密码失败')
					var datastr = JSON.stringify(data, null, 2); 
					console.error(`forgot password response [${datastr}]`)
				}
				// sessionStorage.token = data
			})
		},
		//重置

		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form
			if (value && value !== form.getFieldValue('password')) {
				callback('两次输入的密码不一致')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.form
			if (value && this.confirmDirty) {
				form.validateFields(['confirm'], { force: true })
			}
			callback()
		}
	}
}
</script>

<style scoped lang="scss">
.indexBox {
	width: 1200px;
	height: 500px;
	margin: auto;
	// border: 1px solid rgba(121, 121, 121, 1);
	box-sizing: border-box;
	//登录样式
	.loginBox {
		width: 100%;
		height: 100%;
		background: red($color: #000000);
		h1 {
			margin: auto;
			margin-top: 100px;
			text-align: center;
			font-weight: bold;
		}
		.loginFromBox {
			a {
				color: #1890ff;
				font-weight: 500;
				padding: 12px 16px;
				display: inline-block;
				border-bottom: #1890ff 2px solid;
			}
			.boxleft {
				width: 100%;
				height: 50px;
				text-align: right;
				a {
					display: inline;
					padding: 0;
					border-bottom: 1px solid #37352f;
					font-size: 14px;
					font-variant: tabular-nums;
					border: none;
					color: #37352f;
				}
			}
			margin: auto;
			margin-top: 30px;
			width: 368px;
			height: 350px;
			box-sizing: border-box;
			padding: 50px 0;
			.yueduBOX {
				width: 100%;
				margin-bottom: 20px;
				display: flex;
				justify-content: space-evenly;
				flex-wrap: nowrap;

				div {
					text-align: center;
					flex-grow: 1;
					width: 40%;
					display: inline-block;
				}
			}
			.centerBox {
				display: flex;
				margin-bottom: 10px;
				justify-content: center;
			}
			.a_hre {
				text-align: center;
			}
			p {
				text-align: center;
				font-size: 18px;
				color: black;
			}
			.butonOption {
				width: 120px;
				height: 40px;
				position: relative;
				left: 250px;
				top: -40px;
			}
		}
		.changeTab {
			width: 100%;
			height: 35px;

			display: flex;
			justify-content: space-between;
			span {
				font-size: 18px;
				font-weight: bold;
				display: inline-block;
			}
		}
	}
	//外部样式
	#components-form-demo-normal-login .login-form {
		max-width: 300px;
	}
	#components-form-demo-normal-login .login-form-forgot {
		float: right;
	}
	#components-form-demo-normal-login .login-form-button {
		margin: auto;
		width: 140px;
		height: 45px;
	}
	.a_hre {
		text-align: center;
	}
}
</style>
