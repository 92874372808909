var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"indexBox"},[_c('div',{staticClass:"loginFromBox"},[_vm._m(0),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmitRetrieve}},[_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{
							rules: [
								{
									type: 'email',
									message: '请输入正确的邮箱'
								}
							]
						}
					]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\ttype: 'email',\n\t\t\t\t\t\t\t\t\tmessage: '请输入正确的邮箱'\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"输入重置密码的邮箱"}})],1),_c('a-form-item',{staticClass:"centerBox"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确定重置")])],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticStyle:{"font-size":"14px","line-height":"1.5"}},[_c('a',[_vm._v("找回密码")])])}]

export { render, staticRenderFns }